<div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''" style="padding: 1.5%">
    <div class="col-3 text-right">
        <button type="button" aria-label="Close" class="toast-close-button" (click)="remove()">
            <span aria-hidden="true">×</span>
        </button>
        <button type="button" aria-label="Copy messages to clipboard" class="toast-close-button" style="padding-right: 1%" (click)="copyMessagesToClipboard()">
            <i class="fas fa-copy"></i>
        </button>
    </div>
    <div class="col-9">
        <div [class]="options.titleClass" [attr.aria-label]="title">
            {{ title }}
        </div>
        <div *ngIf="message" role="alert" aria-live="polite"
            [class]="options.messageClass" [attr.aria-label]="message" [innerHTML]="message">
        </div>
        <a class="reveal-more" *ngIf="areExtraMessagesHidden" (click)="areExtraMessagesHidden = !areExtraMessagesHidden">
           Reveal the other {{ getOptionsAsCollapsible().extraMessages.length }} messages
        </a>
        <div *ngIf="!areExtraMessagesHidden">
            <div *ngFor="let message of getOptionsAsCollapsible().extraMessages" style="overflow-x: auto">
                <hr/>
                <span [innerHTML]="message"></span>
            </div>
        </div>
    </div>
</div>