/// /////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE.      //////////////
/// /////////////////////////////////////////////
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public isIframe: boolean = false;
  constructor(
  ) {
    this.isIframe = window !== window.parent && !window.opener;
  }
}
