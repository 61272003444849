export class Tenant {
  name: string;
  address?: string;
  id: string;
  defaultEnv: string;
  stv4Affix: string;
  class: string;
  hidden: boolean;
  description: string;
  environments?: string[];
}
export const TENANTS: Tenant[] = [
  {
    name: 'Studio Dev (IntOne/E2E)',
    address: 'HaloStudioDev.onmicrosoft.com',
    id: '27d5a614-5e32-4ce9-9207-b3a63945b5bb',
    defaultEnv: 'IntOne',
    stv4Affix: '-intone.test',
    class: 'is-success',
    hidden: false,
    description: 'Recommended option if you are not sure. This is where a majority of the studio will configure and develop the game.',
    environments: ['intone', 'e2e'],
  },
  {
    name: 'Halo Production',
    id: '2094aafd-3045-4703-a6f1-ad112ff1babe',
    defaultEnv: 'Prod',
    stv4Affix: '',
    class: 'is-warning',
    hidden: false,
    description: 'For production and RETAIL actions that interact with real players.',
    environments: ['prod'],
  },
  {
    name: 'Studio Stable (Shooter/CertOne)',
    address: 'HaloStudioStable.onmicrosoft.com',
    id: '3693e644-c336-488b-bad9-6bd0f69007f0',
    defaultEnv: 'certone',
    stv4Affix: '-certone.test',
    class: 'is-primary',
    hidden: false,
    description: 'For pre-release, pre-production testing and configuration.',
    environments: ['shooter', 'certOne'],
  },
  {
    name: 'Microsoft (obsolete)',
    address: 'microsoft.onmicrosoft.com',
    id: '72f988bf-86f1-41af-91ab-2d7cd011db47',
    defaultEnv: 'IntOne',
    stv4Affix: '-intone.test',
    class: 'is-danger',
    hidden: true,
    description: 'Retiring soon. Does not support e- accounts.',
    environments: ['play', 'intone', 'e2e', 'shooter', 'cert', 'prod'],
  },
  {
    name: 'Service Dev (Play/CI/Stress)',
    address: 'HaloServiceDev.onmicrosoft.com',
    id: 'a7bbbd40-0cca-4141-91ea-66f422b71fab',
    defaultEnv: 'Play',
    stv4Affix: '-play.test',
    class: 'is-danger',
    hidden: false,
    description: 'A pre-studio-development environment for services developers to test and develop. Avoid this if you\'re not a service dev.',
    environments: ['play'],
  },
];
